@font-face {
  font-family: 'CircularBook';
  font-display: swap;
  font-weight: 400;
  src: url('CircularWeb-Book.woff2') format('woff2');
}
@font-face {
  font-family: 'CircularBold';
  font-display: swap;
  font-weight: 700;
  src: url('CircularWeb-Bold.woff2') format('woff2');
}
