@import '../public/static/fonts/circular/circular.css';

html {
  overflow-y: scroll;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  position: relative;
}

#__next {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: 100vh;
}

a:-webkit-any-link {
  text-decoration: none;
}
